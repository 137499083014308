export const header_html = `
            <div
              class="sc-bZQynM bNmdOy lgN2amz0cDtZbavzXqgD6Q-- header__padding"
            >
              <div
                class="sc-kAzzGY epRoim mobileLogoFlex lM9SsQjXdQKencYIqMYizg-- VckXzlDERs9k2yg2mtENLw--"
              >
                <a class="sc-htpNat bUhGXt link logo" href="" aria-label="Home"
                  ><img
                    alt="logo"
                    src="../Resources/Logo.png"
                    class="sc-ckVGcZ gjctdt"
                /></a>
              </div>
              <div
                class="sc-jzJRlG jGJLow mobileButtonFlex XArGeWWMAngmfSWjkqVxCw-- _1U9wpDtlhiM6tvVR81-C5g-- lgN2amz0cDtZbavzXqgD6Q--"
              >
                <nav class="sc-bxivhb ivbKJD">
                  <ul class="sc-ifAKCX fopjOz sc-kpOJdX kAzjOl">
                    <li class="navigation__link">
                      <a
                        class="sc-htpNat bUhGXt link link current_button"
                        href=""
                        aria-label=""
                        >Current</a
                      >
                    </li>
                    <li class="navigation__link">
                      <a
                        class="sc-htpNat bUhGXt link link archive_button"
                        href=""
                        aria-label=""
                        >Archive</a
                      >
                    </li>
                    <li class="navigation__link">
                      <a
                        class="sc-htpNat bUhGXt link link note_to_authors_button"
                        href=""
                        aria-label=""
                        >Note to Authors</a
                      >
                    </li>
                    <li class="navigation__link">
                      <a
                        class="sc-htpNat bUhGXt link link editorial_team_button"
                        href=""
                        aria-label=""
                        >Editorial Team</a
                      >
                    </li>
                    <li class="navigation__link">
                      <a
                        class="sc-htpNat bUhGXt link link news_button"
                        href=""
                        aria-label=""
                        >News</a
                      >
                    </li>
                    <li class="navigation__link">
                      <a
                        class="sc-htpNat bUhGXt link link contact_button"
                        href=""
                        aria-label=""
                        >Contact</a
                      >
                    </li>
                  </ul>
                </nav>
                
              </div>
            </div>
          
`;
